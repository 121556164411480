// CSS
import 'bootstrap/dist/css/bootstrap.css'; // Import Bootstrap CSS
import '../styles.css';

import {React, useState, useEffect} from 'react';

import {getLightModeStyle, getTextItem, getLinkItem, getTitle} from '../functions.js'

const Message = props => {
    const [lightMode, setLightMode] = useState((props.mode == 'radiate'))

    return (
        <div id={`message${props.id}`} className={(`${lightMode ? 'container pageColor' : 'container pageColor darkMode'} message`)}>
            <div id='messageRow' className={`${(lightMode ? 'row pageColor' : 'row pageColor darkMode')}`}>
                <div className='col-2'>
                    <p className={(!lightMode ? 'textColor darkMode' : 'textColor')}>{(`${props.alias}:`)}</p>
                </div>
                <div className='col-10 d-flex'>
                    <p className={(!lightMode ? 'textColor darkMode' : 'textColor')}>{props.message}</p>
                </div>
            </div>
        </div>
    )
}

export default Message