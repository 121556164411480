// CSS
import 'bootstrap/dist/css/bootstrap.css'; // Import Bootstrap CSS
import '../styles.css';
// Packages
import {React, useState, useEffect} from 'react'
import {getLightModeStyle, getTextItem, getLinkItem, getTitle} from '../functions.js'

const MiniHeader = () => {
    const [mouseX, setMouseX] = useState(0)
    const [mouseY, setMouseY] = useState(0)
    const [locationStr, setLocationStr] = useState('Unknown')
    
    const mousePos = (event) => {
        setMouseX(event.clientX)
        setMouseY(event.clientY)
    }

    const getLocation = (position) => {
        var lat = position.coords.latitude 
        var lng = position.coords.longitude
        var ns = lat > 0 ? 'N' : 'S'
        var ew = lng > 0 ? 'E' : 'W'
        lat = lat > 0 ? lat : lat * -1
        lng = lng > 0 ? lng : lng *-1
        setLocationStr(`${lat}${ns} ${lng}${ew}`)
    }

    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(getLocation);
    } 

    document.documentElement.onmousemove=null
    document.documentElement.onmousemove=mousePos

    return (
        <div className='row d-flex justify-content-center'>
            <div className='col d-flex justify-content-center'>
                <>{getTextItem(false, 'p', 'miniHeaderCoord', {}, locationStr)}</>
            </div>
            <div className='col d-flex justify-content-center'>
                <>{getLinkItem(false, 'miniHeaderEnter', "/enter", {alignSelf:"center", textAlign:"center"}, "enter")}</>
            </div>
            <div className='col d-flex justify-content-center'>
                <>{getLinkItem(false, 'miniHeaderHomeLink', "/",{alignSelf:"center", textAlign:"center"}, getTitle())}</>
            </div>
            <div className='col d-flex justify-content-center'>
                <>{getLinkItem(false, 'miniHeaderJoinUsLink', "/joinus",{alignSelf:"center", textAlign:"center"}, "join us")}</>
            </div>
            <div className='col d-flex justify-content-center'>
                <>{getTextItem(false, 'p', 'miniHeaderCoord', {}, `[X:${mouseX} Y:${mouseY}]`)}</>
            </div>
        </div>
    );
}

export default MiniHeader