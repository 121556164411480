import React, { useState, useEffect, useRef } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { useTextToVoice } from "react-speakup";

async function onEndHandler() {
    console.log('SPEECH ENDED')
    SpeechRecognition.startListening({continuous: true})
}

function Talker() {
  const [start, setStart] = useState(true)
  const [msg, setMsg] = useState(new SpeechSynthesisUtterance())
  const [status, setStatus] = useState("Listening...");
  const [chatGPTResponse, setChatGPTResponse] = useState("");
  const { transcript, resetTranscript } = useSpeechRecognition();
  useEffect(() => {
    // Automatically reset and send transcript to ChatGPT every 5 seconds if there's content
    const interval = setInterval(() => {
      if(start){
        SpeechRecognition.startListening({continuous: true})
        setStart(false)
      }
      if (transcript) {
        setStatus("Processing...");
        sendTranscriptToChatGPT(transcript);
        resetTranscript();  // Clear the transcript for the next input
      }
    }, 3000); // Adjust the interval as needed

    return () => clearInterval(interval);  // Cleanup interval on component unmount
  }, [transcript]);

  const sendTranscriptToChatGPT = async (userPrompt) => {
    setStatus("Sending to ChatGPT...");
    try {
        const response = await fetch("https://api.openai.com/v1/chat/completions", {
            method: "POST",
            headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer `, // Store your key securely//${process.env.OPENAI_API_KEY}
            },
            body: JSON.stringify({
            model: "gpt-3.5-turbo",
            messages: [
                {
                    role: "system",
                    content:"You are an assisstant. we are on a walk together."
                },
                {
                    role: "user",
                    content:userPrompt
                }]
            })
        });
        console.log('Sending chatgpt')
        const data = await response.json();
        setChatGPTResponse(data.choices[0].message.content);
        setStatus("Response received!");
        SpeechRecognition.abortListening().then(()=> {
            msg.text = data.choices[0].message.content;
            window.speechSynthesis.speak( msg );
            function _wait() {
                if ( ! window.speechSynthesis.speaking ) {
                    window.setTimeout( onEndHandler, 3000 );
                    // Just use AWS
                    return;
                }
                window.setTimeout( _wait, 200 );
            }
            _wait();
        })
        .finally(()=> {
            msg.onend=onEndHandler
        })
    } catch (error) {
        console.error("Failed to fetch ChatGPT response:", error);
        setStatus("Error fetching response.");
    }
  };

  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      height: '100vh',
      fontSize: '24px',
      fontFamily: 'Arial, sans-serif',
      color: '#333'
    }}>
      <div>{status}</div>
      <div style={{ marginTop: '20px', textAlign: 'center' }}>
        <h3>Transcription:</h3>
        <p>{transcript}</p>
      </div>
      <div style={{ marginTop: '20px', textAlign: 'center' }}>
        <h3>ChatGPT Response:</h3>
        <div>
            <p>{chatGPTResponse}</p>
        </div>
      </div>
    </div>
  );
}

export default Talker;
