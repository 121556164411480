import {React, useState} from "react";
import axios from "axios";


const onSubmit = (event, company) => {
    event.preventDefault()
    axios.get('https://d2r-server.vercel.app/api/getdata', {
        params: {
            company: company
        }
    })
    .then((res) => {
        if(res.data) {
            console.log(res.data)
        }
    })
    .catch((err) => {
        console.log(err)
    })
}

const Plan = props => {
    const [company, setCompany] = useState('')
    const [showRes, setShowRes] = useState(false)
    if (!showRes){
        return (
            <div>
                <p>Plan</p>
                <input type="text" onChange={(event) => {
                    setCompany(event.target.value)
                }}/>
                <button onClick={(event)=> {
                    onSubmit(event, company)
                    setShowRes(!showRes)
                }}>Submit</button>
            </div>
        )
    }
    else {
        return (
            <div>
                <p>{`${company}`}</p>
                <button onClick={(event)=> {
                    setShowRes(!showRes)
                }}>Submit</button>
            </div>
        )
    }
}

export default Plan