// CSS
import 'bootstrap/dist/css/bootstrap.css'; // Import Bootstrap CSS
import '../styles.css';

import {React, useState, useEffect} from 'react';
import Message from './Message';
import { getLightMode } from '../functions';

const renderMessages = (messages) => {
    return (
        <div className='row'>{messages.map((message) => {
            return (<Message id={message.id} alias={message.alias} message={message.message} mode={message.mode}/>)
        })}</div>
    )
}

const ChatBox = props => {
    const [messages, setMessages] = useState([
        {
            id: '123',
            alias: 'Dom',
            message: 'Hello World',
            mode: 'radiate'
        },
        {
            id: '456',
            alias: 'Kyle',
            message: 'Hey Dom',
            mode: 'radiate'
        },
        {
            id: '789',
            alias: 'Rick',
            message: 'Stop biting your nails',
            mode: 'decay'
        }])
    
    const [message, setMessage] = useState('')
    
    useEffect(() => {
        const textarea = document.getElementById("TB-TextArea");
        const parentDiv = document.getElementById("ChatBox-PD");
        const above = document.getElementById("ChatBox-MessageContainer")
        textarea.addEventListener("input", function () {
            this.style.height = "auto";  // Reset height to auto to allow shrinking
            const maxHeight = above.clientHeight - parentDiv.clientHeight - 20;  // Adjust for padding/border
            const scrollHeight = this.scrollHeight - 16;
            console.log(`max Height: ${maxHeight}`)
            console.log(`scroll Height: ${scrollHeight}`)
            // Set height to scrollHeight if it doesn't exceed maxHeight
            if (scrollHeight < maxHeight) {
                this.style.height = scrollHeight + "px";
            } else {
                this.style.height = maxHeight + "px";
            }
        });
    }, [])
    
    return (
        <div id='ChatBox-PD' className='container'>
            <div id='ChatBox-MessageContainer' className='container'>{renderMessages(messages)}</div>
            <div className='row'>
                <div className='col-10  d-flex justify-content-center'>
                    <textarea id='TB-TextArea' placeholder='Into the void...' value={message.message} onInput={(event) => {
                        setMessage({
                            id: 'x',
                            alias: 'alias',
                            message: event.target.value,
                            mode: (getLightMode() ? 'radiate' : 'decay')
                        })
                    }}/>
                </div>
                <div className='col-2  d-flex justify-content-center align-items-center'>
                    <button id='TB-Respond-btn' onClick={(event) => {
                        event.preventDefault()
                        let msgs = messages.slice()
                        msgs.push(message)
                        setMessages(msgs)
                        setMessage({
                            id: 'x',
                            alias: 'alias',
                            message: '',
                            mode: (getLightMode() ? 'radiate' : 'decay')
                        })
                    }}></button>
                </div>
            </div>
        </div>
    )
}

export default ChatBox