// CSS
import 'bootstrap/dist/css/bootstrap.css'; // Import Bootstrap CSS
import 'bootstrap/dist/js/bootstrap.bundle'; // This fixed the issue with dropdowns?
//import '../styles.css';
// Packages
import {React, useState, useEffect } from 'react'
import {getLightModeStyle, getTextItem, getLinkItem, getTitle} from '../functions.js'
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
// Components
import Navbar from '../Components/Navbar';
import MiniHeader from '../Components/MiniHeader.js';

const onSubmit = (event, navigate, setCookie, deviceID, alias, password) => {
    event.preventDefault()
    axios.post('https://d2r-server.vercel.app/api/signin', {
        params: {
            deviceID: deviceID,
            alias: alias,
            password: password
        }
    })
    .then((res) => {
        const { data } = res;
        
        if (data && data.alias) {
            // Set cookie for the logged-in user
            setCookie('alias', data.alias);
            alert(`Welcome ${data.alias}!`);
            navigate("/");
        } else {
            // Handle case when response doesn't contain expected data
            alert('Login failed: ' + data.error || 'Unknown error');
        }
    })
    .catch((err) => {
        // Log and handle error response
        console.error('Error during login request:', err);
        
        // Check for server-side error response
        if (err.response) {
            alert(`Error: ${err.response.data.error || 'Unknown server error'}`);
        } else {
            alert('Request failed. Please check your network connection.');
        }
    });    
}

const Login = () => {
    const [cookies, setCookie, removeCookie] = useCookies([]);
    const [lightMode, setLightMode] = useState(localStorage.getItem('lightMode')=='true')
    const [alias, setAlias] = useState('')
    const [key, setKey] = useState('')
    const navigate = useNavigate();

    return (
        <div className={`container page-container ${getLightModeStyle('container')}`}>
            <Navbar onToggleLightMode={(value) => {
                    setLightMode(value)
                }}
            />
            <MiniHeader/>
            <div className='container d-flex flex-column' style={{height:"100%", width:'50%', paddingTop:"10%"}}>
                <div className='container d-flex justify-content-center' style={styles.spacerTop}>
                    <>{getTextItem(false, 'h6', 'joinUsAliasLable', {width:'100px'}, "alias:")}</>
                    <input type='text' style={styles.input} onChange={(event) => {
                        setAlias(event.target.value)
                    }}/> 
                </div>
                <div className='container d-flex justify-content-center' style={styles.spacerTop}>
                    <>{getTextItem(false, 'h6', 'joinUsKeyLable', {width:'100px'}, "key:")}</>
                    <input type='text' style={styles.input} onChange={(event) => {
                        setKey(event.target.value)
                    }}/> 
                </div>
                <div className='container d-flex justify-content-center'>
                    <button 
                    className={(lightMode ? 'btn textColor' : 'btn textColor darkMode')} 
                    id='joinUsSubmitBtn'
                    onClick={(event)=> {
                        onSubmit(event, navigate, setCookie, cookies['deviceID'], alias, key)
                    }}>enter</button>
                </div>
            </div>
        </div>
    )
}

const styles = {
    input: {
        flex:'1'
    },
    spacerTop: {
        paddingTop:'1%'
    }
}

export default Login